import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import AuthSelectors from 'Stores/Auth/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import StationSelectors from 'Stores/Station/Selectors'
import SubscriptionList from '../SubscriptionList/SubscriptionList'
import TenantUserActions from 'Stores/TenantUser/Actions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import { calculateConnectorsHeight } from 'Utils/ConnectorFunctions'
import { connect } from 'react-redux'

const ChargePointSubscription = forwardRef(
  (
    {
      chargePoint,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointRequestState,
      isEditing,
      fetchChargePointSubscriptionsList,
      fetchSubscriptionsList,
      chargePointSubscriptions,
      fetchChargePointSubscriptionsRequestState,
      subscriptionsList,
      deleteChargePointSubscriptions,
      addChargePointSubscriptions,
      maxListHeight,
      removeExtraMargin,
      chargePointUid,
      tenant,
    },
    ref,
  ) => {
    const subscriptionsListRef = useRef()
    useEffect(() => {
      if (
        (chargePoint && !isEditing) ||
        (chargePoint && chargePoint.isActivated === false)
      ) {
        fetchChargePointSubscriptionsList(chargePoint._id)
        fetchSubscriptionsList()
      }
    }, [chargePointUid])

    const handleRefresh = () => {
      fetchChargePointSubscriptionsList(chargePoint._id)
      fetchSubscriptionsList()
    }

    const onSubmit = (addSubscriptions, deleteSubscriptions) => {
      if (deleteSubscriptions.length > 0)
        deleteChargePointSubscriptions(chargePoint._id, deleteSubscriptions)
      if (addSubscriptions.length > 0)
        addChargePointSubscriptions(chargePoint._id, addSubscriptions)
    }

    const handleSubmit = (resetValues) => {
      subscriptionsListRef?.current?.handleSubmit(resetValues)
    }

    const handleCancel = () => {
      subscriptionsListRef?.current?.handleCancel()
    }

    const didChange = () => {
      return subscriptionsListRef?.current?.didChange()
    }

    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleRefresh,
      handleCancel,
      didChange,
    }))

    const isLoading = () => {
      return (
        fetchStationListRequestState === RequestState.LOADING ||
        fetchChargePointListRequestState === RequestState.LOADING ||
        fetchChargePointRequestState === RequestState.LOADING ||
        fetchChargePointSubscriptionsRequestState === RequestState.LOADING
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: isEditing && !isLoading() ? 'unset' : 'hidden',
        }}
      >
        <SubscriptionList
          ref={subscriptionsListRef}
          displayedList={chargePointSubscriptions}
          dropdownList={subscriptionsList}
          isEditing={isEditing}
          isLoading={isLoading()}
          maxListHeight={maxListHeight}
          scrollbarsHeight={
            isLoading()
              ? '68px'
              : calculateConnectorsHeight(chargePoint?.connectors)
          }
          onSubmit={onSubmit}
          removeExtraMargin={removeExtraMargin}
          country={tenant.country}
        />
      </div>
    )
  },
)
function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointSubscriptionsList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointSubscriptionsList(chargePointId),
      ),
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),
    deleteChargePointSubscriptions: (chargePointId, subscriptions) =>
      dispatch(
        ChargePointActions.deleteChargePointSubscriptions(
          chargePointId,
          subscriptions,
        ),
      ),
    addChargePointSubscriptions: (chargePointId, subscriptions) =>
      dispatch(
        ChargePointActions.addChargePointSubscriptions(
          chargePointId,
          subscriptions,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargePointSubscriptions:
    ChargePointSelectors.getChargePointSubscriptions(state),
  fetchChargePointSubscriptionsRequestState:
    ChargePointSelectors.getFetchChargePointSubscriptionsRequestState(state),
  subscriptionsList: TenantUserSelectors.getSubscriptionsList(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
  tenant: AuthSelectors.getTenant(state),
})

ChargePointSubscription.propTypes = {
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  fetchChargePointRequestState: PropTypes.number,
  isEditing: PropTypes.bool,
  fetchChargePointSubscriptionsList: PropTypes.func,
  fetchSubscriptionsList: PropTypes.func,
  chargePointSubscriptions: PropTypes.array,
  fetchChargePointSubscriptionsRequestState: PropTypes.number,
  subscriptionsList: PropTypes.array,
  deleteChargePointSubscriptions: PropTypes.func,
  addChargePointSubscriptions: PropTypes.func,
  subscriptionsListRef: PropTypes.object,
  maxListHeight: PropTypes.any,
  chargePointUid: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargePointSubscription)
