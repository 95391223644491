/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const Colors = {
  //common
  text: '#000000',
  primary: '#27a3ac',
  defaultPrimary: '#27a3ac',
  primaryLight: '#CEDADB',
  success: '#28a745',
  error: '#dc3545',
  white: '#FFFFFF',
  black: '#000000',
  red: '#FF0000',
  shadow: '#00287A',
  grey: '#e9e9e9',
  whiteSmoke: '#F5F5F5',
  BoxShadow: '#00000040',
  linkColor: '#0645AD',

  //ChargePointLogListItem
  ChargePointLogListItemBoxShadow: '#00000040',
  ChargePointLogListItemCodeSnippet: '#f4f4f4',
  ChargePointLogListItemHover: '#f3f9fb',
  ChargePointLogListItemCopy: '#171717',
  ChargePointLogListItemCopyHover: '#e1e1e1',

  //IFModal
  backdrop: '#000000',
  exitIcon: '#000000',

  // Side Navigation Bar
  LogoBackground: '#3dacb4',
  NavigationBarBackground: '#27a3ac',
  IconHover: '#52b5bd',

  // Header Bar
  HeaderBarBoxShadow: '#00000040',

  // StationListHeader
  StationListHeaderBackground: '#F0F8FA',

  // StationListItem
  StationListItemBackgroundHover: '#F0F8FA',
  StationListItemSelected: '#E4F3F7',

  AvailableConnector: '#1F8B24', //Green
  PreparingConnector: '#d3d300', //Yellow
  SuspendedConnector: '#d3d300', //Yellow
  ReservedConnector: '#d3d300', //Yellow
  ChargingConnector: '#1672EC', //Blue
  FinishingConnector: '#1672EC', //Blue
  UnAvailableConnector: '#FF0000', //Red
  FaultedConnector: '#FF0000', // Red
  OfflineConnector: '#818181', //Grey
  TransparentBackground: '#ffffff00',
  LastUpdatedColor: '#00000080',

  filterBackground: '#e8f6f7',
  deadButton: 'gray',
  transparent: 'transparent',

  // IFRangePicker
  IFRangePickerShadow: '#0000001a',
  IFRangePickerButton: '#3597ff',

  // Filter
  FilterShadow: '#0000002f',
  FilterClearButton: '#E1E1E1ff',
  FilterBackground: '#e8f6f7',
  FilterIconEmpty: '#808080',
  FilterIconFilled: '#000000',
  ChargingTransactionListItemShadow: '#0000001a',

  //tooltip
  tooltipGrey: '#808080',

  //ConfigurationListItem
  ConfigurationListItemBackground: '#e1ffff1a',
  ConfigurationListItemBoxShadow: '#00000080',

  //ChargePointLogTransaction
  ChargePointLogTransactionItemBoxShadow: '#00000040',
  ChargePointLogTransactionItemBackground: '#e1ffff1a',

  //UserTransactionListItem
  UserTransactionItemBackground: '#e1ffff1a',

  // ChargePointTab
  LowOpacityText: '#00000033',

  // ChargePointTabs
  TabsBackground: '#27A3AC1A',

  // Connector List Item
  MaxPowerBackground: '#C4C4C480',
  GreyBackground: '#F4F4F4',
  StatusYellowText: '#b3c117',
  BoxShadowColor: '#00000040',

  // Upload Drop Zone
  DropZoneBackground: '#e1e1e1',

  // Upload Diagnostics Modal
  CurrentFirmwareText: '#000000',
  UploadModalUpdateText: '#000000',

  //login
  loginBackgroundColor: '#fffffff2',
  loginBackgroundShadow: '#00000040',

  // ChargePointDetails
  DividerColor: '#0000001a',
  tooltipGreyBackground: '#0000004D',
  tooltipBoxShadow: ' #00000040',
  tooltipText: '#00000066',

  // ChargePointDetailsTabs
  NotSelectedText: '#0000004D',

  //Reset password
  resetPasswordBackgroundColor: '#fffffff2',
  resetPasswordBackgroundShadow: '#00000040',
  resetPasswordModalSubTitle: '#000000',
  pinFieldBackground: '#f8f9fa',
  pinFieldBorder: '#cccccc',
  pinFieldInvalidBorder: '#dc3545',
  pinFieldInvalidBoxShadow: '#dc354580',
  //
  background: '#F6F6F6',
  SideBarItemHover: '#ffffff80',

  // Empty States
  emptyStatesSubTitle: '#818181',

  // Skeleton
  skeleton: '#e3e3e3',

  //Tenant User List Item
  TenantUserListItemEmail: '#00000099',
  TenantUserListPenIcon: '#818181',
  TenantUserListDeleteIcon: '#ff0000',

  //Tenant User List
  TenantUserListDeleteModalCancel: '#27a3ac',
  TenantUserListDeleteModalDelete: '#FF0000',

  // Create Account
  CreateAccountBackgroundColor: '#fffffff2',
  CreateAccountBackgroundShadow: '#00000040',

  //IFToastMessage
  ToastInfo: '#2196f3',
  ToastSuccess: '#4caf50',
  ToastError: '#f44336',
  ToastWarning: '#ffb300',

  // CardListItem
  ActiveCard: '#1F8B24',
  BlockedCard: '#FF0000',
  DeActivatedCard: '#818181',
  CardListItemShadow: '#0000001a',
  ClipboardCopy: '#808080',

  TransactionDownload: '#808080',

  HexTransparent: {
    T100: 'FF',
    T95: 'F2',
    T90: 'E6',
    T85: 'D9',
    T80: 'CC',
    T75: 'BF',
    T70: 'B3',
    T65: 'A6',
    T60: '99',
    T55: '8C',
    T50: '80',
    T45: '73',
    T40: '66',
    T35: '59',
    T30: '4D',
    T25: '40',
    T20: '33',
    T15: '26',
    T10: '1A',
    T5: '0D',
    T0: '00',
  },

  //Stations Map
  MapViewToggleSelected: '#000000',
  MapViewToggleNotSelected: '#0000004d',
  MapViewToggleBackground: '#FFFFFF',
  ConnectorComponentBorder: '#c4c4c480',
  ChargerSpeedContainerBackground: '#c4c4c480',
  ChargePointNameText: '#000000',
  ClusterCountText: '#FFFFFF',
  StationMapComponentBackground: '#FFFFFF',
  StationHeaderBackground: '#FFFFFF',
  StationHeaderText: '#000000',
  StationBodyBackground: '#FFFFFF',

  // tooltip
  DeadTooltip: '#A9A9A9',

  // Spinner
  SpinnerSecondaryColor: '#FFFFFF4D',

  ToggleDisabled: '#c5c6c7',

  // File color
  FileColor: '#8b949e',
  // ChargePoint Privacy Picker
  PrivacyPickerPublic: '#1F8B24B2',
  PrivacyPickerPrivate: '#FF0000B2',

  ChargePointSubscriptionListItem: {
    RemoveIcon: '#FF0000',
    AddIcon: '#1F8B24',
    gray: '#0000004d',
    black: '#000000',
    defaultSub: '#22BADB',
  },

  ChargePointScheduleListItem: {
    RemoveIcon: '#FF0000',
    AddIcon: '#1F8B24',
    gray: '#0000004d',
    black: '#000000',
  },

  // Add button
  ChargePointSpecsAdd: '#1F8B24',
  ChargePointSpecsGreyedOut: '#0000004D',
  ChargePointSpecsEdit: {
    ListBoxShadow: '#00000040',
  },

  // ChargePoint Specs Container
  ChargePointSpecsTabsNotSelected: '#0000004D',
  ChargePointSpecsListContainerShadow: '#00000040',

  // User List Item
  UserListItemEmail: '#00000080',
  UserActive: '#1F8B24',
  UserBlocked: '#FF0000',

  // UsersPage
  UserGroupsContainer: '#00000040',

  //
  ChargingTokenListItemBackgroundHover: '#F0F8FA',
  ChargingTokenListItemSelected: '#E4F3F7',
  ChargingTokenListItemUid: '#00000080',
  AvailableChargingToken: '#1F8B24', //Green
  BlockedChargingToken: '#FF0000', //Red
  DeactivatedChargingToken: '#818181', //Grey
  TransparentBackground: '#ffffff00',
  StatusContainerBackground: '#C4C4C433',
  ChargingtokenStatus: {
    Active: '#1F8B24B2',
    Blocked: '#FF0000B2',
    Deactivated: '#818181',
  },

  // Station Uptime Monitor
  StationUptimeStatus: {
    Online: '#1f8b24',
    Offline: '#b3b3b3',
    Unstable: '#ff4500',
  },

  // Connectors Utilization
  ConnectorStatus: {
    AVAILABLE: '#1F8B24',
    CHARGING: '#1672EC',
    OCCUPIED: '#d3d300',
    UNAVAILABLE: '#818181',
    FAULTED: '#FF0000',
  },

  MapSearchBoxDivider: '#a9a9a9',
  MapSearchBoxIcons: '#5A5A5A',
  MapSearchLightGray: '#adadad',

  TransactionStatus: {
    Void: '#808080',
    Corrected: '#008000',
    Normal: '##00000000',
    Reversed: '#FF0000',
  },

  AlertUnstableText: '#d3d300',
  AlertFaultedText: '#FF0000',
  AlertOfflineText: '#818181',
  AlertOfflineBackground: '#F4F4F4',

  AlertItem: {
    AddIcon: '#1F8B24',
    GreyedOut: '#0000004D',
    Hover: '#f3f9fb',
  },

  ScheduleChartTick: '#e0e0e0',
}

export default Colors
