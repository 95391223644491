import { AddCircle, DoNotDisturbOn } from '@mui/icons-material'
import React, { useCallback, useRef } from 'react'
import { useEffect, useState } from 'react'

import { Colors } from 'Theme'
import Fade from '@mui/material/Fade'
import IFText from 'Components/IFText/IFText'
import InfoIcon from '@mui/icons-material/Info'
import PropTypes from 'prop-types'
import Styles from './ChargePointSubscriptionListItem.module.css'
import Tooltip from '@material-ui/core/Tooltip'
import { isOverflowActive } from 'Utils/Calculations'
import { isPropsMatch } from 'Utils/PropsMatch'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/styles'

function isBeforeMidnightAndDifference(date) {
  // Get the start of the next day (midnight)
  const nextMidnight = new Date(date)
  nextMidnight.setHours(24, 0, 0, 0) // Set to midnight of the next day
  const isBeforeMidnight = date.getUTCDate() !== nextMidnight.getUTCDate()
  const midnight = moment.utc(date).add(1, 'day').startOf('day')
  const startTimeDate = moment.utc(date)
  // Calculate the difference in hours in UTC
  const differenceInHours = midnight.diff(startTimeDate, 'hours')
  return {
    isBeforeMidnight: isBeforeMidnight,
    differenceInHours,
  }
}

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '0rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
    width: '21rem',
    maxWidth: '21rem',
  },
})(Tooltip)

const StyledOverflowTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '-1.4rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
    // width: 'max-content',
    // maxWidth: '100%',
    marginLeft: '-0.35rem',
    paddingRight: '0.6rem',
  },
})(Tooltip)
const paletteArray = [
  '#4dabd7',
  '#2fce9a',
  '#7453d8',
  '#d74f6c',
  '#b7c42c',
  '#239b5d',
  '#b2da5e',
  '#6876dd',
  '#b7b62a',
  '#912ab8',
  '#5552d8',
]
const ChargePointSubscriptionListItem = ({
  _id,
  name,
  expiryDate,
  consumedPower,
  power,
  isEditing,
  isGlobal,
  minDCTariff,
  minACTariff,
  maxACTariff,
  maxDCTariff,
  isDefault,
  tariffs,
  timeSlots,
  country,
  onClick = () => {},
}) => {
  const [toBeRemoved, setToBeRemoved] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [currentTimeSlot, setCurrentTimeSlot] = useState()
  const { t } = useTranslation()
  const textRef = useRef()
  useEffect(() => {
    timeSlots.sort((a, b) => {
      const dateA = new Date(a.startTime)
      const dateB = new Date(b.startTime)
      return dateA - dateB
    })
    let dateNow = moment()
    let validCommonSub = timeSlots?.find((slot) => {
      let startTime = moment(slot.startTime)
      let endTime = moment(slot.endTime)
      var differenceInHours = endTime.diff(startTime, 'hours')
      startTime = moment().set({
        hour: startTime.hour(),
        minute: startTime.minute(),
        second: startTime.second(),
        millisecond: startTime.millisecond(),
      })
      endTime = moment(startTime).add(differenceInHours, 'hours')

      return (
        startTime.toDate() <= dateNow.toDate() &&
        dateNow.toDate() < endTime.toDate()
      )
    })
    setCurrentTimeSlot(validCommonSub)

    let graphArray = []
    const { isBeforeMidnight, differenceInHours } =
      isBeforeMidnightAndDifference(new Date(timeSlots?.[0].startTime))
    for (const [i, t] of timeSlots.entries()) {
      const startTime = moment(t.startTime)
      const endTime = moment(t.endTime)

      let difference = Math.ceil(endTime.diff(startTime, 'hour', true))
      let percent =
        ((difference - (isBeforeMidnight && i === 0 ? differenceInHours : 0)) /
          24) *
        100

      graphArray.push({
        style: {
          backgroundColor:
            t._id === validCommonSub?._id ? Colors.primary : paletteArray[i],
          width: `calc(${percent}%)`,
          height: 12,
        },
        tariffs: t.tariffs.map((t) => t._id),
      })
    }
    if (isBeforeMidnight) {
      graphArray.push(JSON.parse(JSON.stringify(graphArray[0])))
      graphArray[graphArray.length - 1].style.width = `calc(${
        (differenceInHours / 24) * 100
      }%)`
    }
    graphArray[0].style = {
      ...graphArray[0].style,
      borderRadius: '2px 0 0 2px',
    }
    graphArray[graphArray.length - 1].style = {
      ...graphArray[graphArray.length - 1].style,
      borderRadius: '0 2px 2px 0',
    }
    setGraphData(graphArray)
  }, [timeSlots])
  useEffect(() => {
    if (!isEditing) setToBeRemoved(false)
  }, [isEditing])
  const getTariffColor = useCallback(
    (tariff) => {
      if (!graphData) return Colors.primary
      let indexes = []
      for (let i = 0; i < timeSlots.length; i++) {
        if (timeSlots[i].tariffs.map((t) => t._id).includes(tariff._id)) {
          indexes.push(i)
        }
      }
      let colors = []
      indexes.forEach((i) => {
        colors.push(graphData[i]?.style?.backgroundColor)
      })
      return colors
    },
    [graphData],
  )
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        {isEditing ? (
          isDefault ? null : (
            <div
              className={Styles.IconContainer}
              onClick={() => {
                setToBeRemoved((prevState) => {
                  onClick(_id, !prevState)
                  return !prevState
                })
              }}
            >
              {toBeRemoved ? (
                <AddCircle
                  className={Styles.Icon}
                  style={{
                    color: Colors.ChargePointSubscriptionListItem.AddIcon,
                  }}
                />
              ) : (
                <DoNotDisturbOn
                  className={Styles.Icon}
                  style={{
                    color: Colors.ChargePointSubscriptionListItem.RemoveIcon,
                  }}
                />
              )}
            </div>
          )
        ) : null}

        {isOverflowActive(textRef.current) ? (
          <StyledOverflowTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 60 }}
            title={
              <div>
                <IFText
                  className={Styles.ToolTipOverflownText}
                  style={{
                    color: toBeRemoved
                      ? Colors.ChargePointSubscriptionListItem.gray
                      : Colors.ChargePointSubscriptionListItem.black,
                  }}
                >
                  {name}
                </IFText>
              </div>
            }
            placement="bottom-start"
          >
            <div className={Styles.TextContainer}>
              <IFText
                ref={textRef}
                className={Styles.Text}
                style={{
                  color: toBeRemoved
                    ? Colors.ChargePointSubscriptionListItem.gray
                    : Colors.ChargePointSubscriptionListItem.black,
                }}
              >
                {name}
              </IFText>
            </div>
          </StyledOverflowTooltip>
        ) : (
          <div className={Styles.TextContainer}>
            <IFText
              ref={textRef}
              className={Styles.Text}
              style={{
                color: toBeRemoved
                  ? Colors.ChargePointSubscriptionListItem.gray
                  : isDefault
                  ? Colors.ChargePointSubscriptionListItem.defaultSub
                  : Colors.ChargePointSubscriptionListItem.black,
              }}
            >
              {name}
            </IFText>
          </div>
        )}

        {!isEditing && (
          <StyledTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 60 }}
            title={
              <div className={Styles.ToolTipWrapper}>
                <div className={Styles.SubscriptionNameContainer}>
                  <IFText
                    className={Styles.SubscriptionName}
                    style={{
                      color: Colors.black,
                    }}
                  >
                    {name}
                  </IFText>

                  <IFText
                    className={Styles.SubscriptionName}
                    style={{
                      color: Colors.ChargePointSubscriptionListItem.defaultSub,
                    }}
                  >
                    {isDefault ? t('SubscriptionListItem.Default') : ''}
                  </IFText>
                </div>
                <div className={Styles.PowerExpiry}>
                  <div className={Styles.Power}>
                    <IFText className={Styles.PowerText}>
                      {t('SubscriptionListItem.ConsumedEnergy')}
                    </IFText>
                    <IFText className={Styles.PowerValue}>
                      {power && consumedPower
                        ? `${consumedPower.toFixed(2)} / ${power.toFixed(0)}`
                        : '-'}
                    </IFText>
                  </div>

                  <div className={Styles.Power}>
                    <IFText className={Styles.PowerText}>
                      {t('SubscriptionListItem.ExpiryDate')}
                    </IFText>
                    <IFText className={Styles.PowerValue}>
                      {expiryDate ? expiryDate : '-'}
                    </IFText>
                  </div>
                </div>
                <div className={Styles.Hours}>
                  <IFText className={Styles.HoursText}>
                    {t('SubscriptionListItem.Midnight')}
                  </IFText>
                  <IFText className={Styles.HoursText}>
                    {t('SubscriptionListItem.Midnight')}
                  </IFText>
                </div>

                <div className={Styles.LineGraph}>
                  {graphData.map((s) => {
                    return <div style={s.style} />
                  })}
                </div>
                <div className={Styles.GraphInfo}>
                  <div
                    className={Styles.Box}
                    style={{ backgroundColor: Colors.primary }}
                  ></div>
                  <IFText className={Styles.CurrentTariffText}>
                    {t('SubscriptionListItem.CurrentTariff')}
                  </IFText>
                </div>

                <IFText
                  className={Styles.Tariffs}
                  style={{
                    color: Colors.black,
                  }}
                >
                  {t('SubscriptionListItem.Tariffs')}
                </IFText>
                <div className={Styles.TariffsContainer}>
                  {tariffs?.map((tariff, i) => {
                    return (
                      <div className={Styles.TariffItem}>
                        <div className={Styles.TariffCell}>
                          <IFText className={Styles.CurrentTariffText}>
                            {tariff.name}
                          </IFText>
                          {getTariffColor(tariff).map((color) => {
                            return (
                              <div
                                className={Styles.BoxTariff}
                                style={{
                                  backgroundColor: color,
                                }}
                              />
                            )
                          })}
                        </div>
                        <div className={Styles.TariffCellMiddle}>
                          <IFText className={Styles.CurrentTariffText}>
                            {tariff.currentType}
                          </IFText>
                        </div>
                        <div className={Styles.TariffCellMiddle}>
                          <IFText className={Styles.CurrentTariffText}>
                            {parseFloat(
                              Number(tariff.pricePerWh * 1000).toFixed(
                                country.currency.decimalPlaces,
                              ),
                            ) +
                              ' ' +
                              country.currency.code}
                          </IFText>
                        </div>
                        <div className={Styles.TariffCellMiddle}>
                          <IFText className={Styles.CurrentTariffText}>
                            {tariff.pointsPerWh * 1000 +
                              ' ' +
                              t('SubscriptionListItem.PointsCode')}
                          </IFText>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            }
            placement="bottom-start"
          >
            <InfoIcon className={Styles.infoIcon} />
          </StyledTooltip>
        )}
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.PriceTextContainer}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointSubscriptionListItem.gray
                : Colors.ChargePointSubscriptionListItem.black,
            }}
            className={Styles.Text}
          >
            {minACTariff === maxACTariff
              ? parseFloat(
                  Number(maxACTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                )
              : parseFloat(
                  Number(minACTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                ) +
                ' - ' +
                parseFloat(
                  Number(maxACTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                )}
          </IFText>
        </div>
        <div className={`${Styles.PriceTextContainer}`}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointSubscriptionListItem.gray
                : Colors.ChargePointSubscriptionListItem.black,
            }}
            className={Styles.Text}
          >
            {minDCTariff === maxDCTariff
              ? parseFloat(
                  Number(maxDCTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                )
              : parseFloat(
                  Number(minDCTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                ) +
                ' - ' +
                parseFloat(
                  Number(maxDCTariff * 1000).toFixed(
                    country.currency.decimalPlaces,
                  ),
                )}
          </IFText>
        </div>
      </div>
    </div>
  )
}

ChargePointSubscriptionListItem.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  tariff: PropTypes.object,
  expiryDate: PropTypes.string,
  consumedPower: PropTypes.string,
  power: PropTypes.string,
  isEditing: PropTypes.bool,
  onClick: PropTypes.func,
  isGlobal: PropTypes.bool,
}
function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    '_id',
    'name',
    'tariff',
    'expiryDate',
    'consumedPower',
    'power',
    'isEditing',
    'isGlobal',
    'onClick',
  ])
}
export default React.memo(ChargePointSubscriptionListItem, shouldSkipRender)
