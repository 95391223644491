import React, { useEffect, forwardRef } from 'react'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SubscriptionList from '../SubscriptionList/SubscriptionList'
import TenantUserActions from 'Stores/TenantUser/Actions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import CardSelectors from 'Stores/Card/Selectors'
import CardActions from 'Stores/Card/Actions'

const ChargingTokensSubscriptionContainer = forwardRef(
  (
    {
      isEditing,
      fetchSubscriptionsList,
      selectedCard,
      fetchChargingTokenRequestState,
      fetchChargingTokenListRequestState,
      addChargingTokenSubscriptions,
      deleteChargingTokenSubscriptions,
      subscriptionsList,
      maxListHeight,
      scrollbarsHeight,
      removeExtraMargin,
      tenant,
    },
    ref,
  ) => {
    useEffect(() => {
      if (selectedCard) {
        fetchSubscriptionsList()
      }
    }, [selectedCard])

    const onSubmit = (addSubscriptions, deleteSubscriptions) => {
      if (deleteSubscriptions.length > 0)
        deleteChargingTokenSubscriptions(selectedCard._id, deleteSubscriptions)
      if (addSubscriptions.length > 0)
        addChargingTokenSubscriptions(selectedCard._id, addSubscriptions)
    }

    return (
      <div style={{ height: '100%' }}>
        <SubscriptionList
          ref={ref}
          displayedList={
            selectedCard?.subscriptions ? selectedCard?.subscriptions : []
          }
          dropdownList={subscriptionsList}
          isEditing={isEditing}
          isLoading={
            fetchChargingTokenRequestState === RequestState.LOADING ||
            fetchChargingTokenListRequestState === RequestState.LOADING
          }
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={onSubmit}
          removeExtraMargin={removeExtraMargin}
          country={tenant.country}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),
    addChargingTokenSubscriptions: (cardId, subscriptions) =>
      dispatch(CardActions.addCardSubscriptions(cardId, subscriptions)),
    deleteChargingTokenSubscriptions: (cardId, subscriptions) =>
      dispatch(CardActions.deleteCardSubscriptions(cardId, subscriptions)),
  }
}

const mapStateToProps = (state) => ({
  subscriptionsList: TenantUserSelectors.getSubscriptionsList(state),
  selectedCard: CardSelectors.getSelectedCard(state),
  fetchChargingTokenRequestState: CardSelectors.getFetchCardRequestState(state),
  fetchChargingTokenListRequestState:
    CardSelectors.getFetchCardListRequestState(state),
  tenant: AuthSelectors.getTenant(state),
})

ChargingTokensSubscriptionContainer.propTypes = {
  isEditing: PropTypes.bool,
  fetchSubscriptionsList: PropTypes.array,
  selectedCard: PropTypes.object,
  fetchUser: PropTypes.func,
  fetchChargingTokenRequestState: PropTypes.number,
  fetchChargingTokenListRequestState: PropTypes.number,
  addChargingTokenSubscriptions: PropTypes.func,
  deleteChargingTokenSubscriptions: PropTypes.func,
  subscriptionsList: PropTypes.array,
  subscriptionsListRef: PropTypes.object,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargingTokensSubscriptionContainer)
