import {
  ChargePointSubscriptionListItem,
  ChargePointSubscriptionListItemSkeleton,
  ChargingSpecsAdd,
  CustomScrollbar,
} from 'Components'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import InfinityEnums from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'
import Styles from './SubscriptionList.module.css'
import { Virtuoso } from 'react-virtuoso'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const SubscriptionList = forwardRef(
  (
    {
      displayedList = [],
      dropdownList = [],
      isEditing,
      isLoading,
      maxListHeight,
      scrollbarsHeight,
      onSubmit = () => {},
      removeExtraMargin,
      country,
    },
    ref,
  ) => {
    const [deleteSubscriptions, setDeleteSubscriptions] = useState([])
    const [addSubscriptions, setAddSubscriptions] = useState([])
    const [filteredSubscriptions, setfilteredSubscriptions] = useState([])
    const [newSubscriptions, setNewSubscriptions] = useState([])
    const [dynamicHeight, setDynamicHeight] = useState(0)
    const [combinedSubscriptions, setCombinedSubscriptions] =
      useState(displayedList)
    const { t } = useTranslation()

    // Work around for displayedList firing useEffect without it's value changing
    const [newDisplayedList, setNewDisplayedList] = useState(displayedList)
    useEffect(() => {
      if (JSON.stringify(displayedList) !== JSON.stringify(newDisplayedList)) {
        setNewDisplayedList(displayedList)
      }
    }, [displayedList])

    useEffect(() => {
      const formattedSubscriptions = displayedList.map((sub) => {
        return {
          ...sub,
          origin: 'original',
        }
      })
      setCombinedSubscriptions(formattedSubscriptions)
    }, [displayedList])

    useEffect(() => {
      setDeleteSubscriptions([])
      setAddSubscriptions([])
      setNewSubscriptions([])
    }, [isEditing])

    useEffect(() => {
      let difference = dropdownList.filter((sub) => {
        const found = newDisplayedList.filter((chargePointSub) => {
          return chargePointSub?.subscription?._id === sub._id
        })
        return found.length === 0
      })
      difference = difference.map((sub) => {
        let minACTariff = Number.MAX_SAFE_INTEGER
        let maxACTariff = Number.MIN_SAFE_INTEGER
        let minDCTariff = Number.MAX_SAFE_INTEGER
        let maxDCTariff = Number.MIN_SAFE_INTEGER
        let tariffs = []
        let timeSlots = sub?.subscription?.timeSlots || sub?.timeSlots
        timeSlots?.forEach((timeslot) => {
          timeslot.tariffs.forEach((tariff) => {
            tariffs.push(tariff)
            if (tariff.currentType === InfinityEnums.CurrentType.AC) {
              if (tariff.pricePerWh < minACTariff)
                minACTariff = tariff.pricePerWh
              if (tariff.pricePerWh > maxACTariff)
                maxACTariff = tariff.pricePerWh
            } else {
              if (tariff.pricePerWh < minDCTariff)
                minDCTariff = tariff.pricePerWh
              if (tariff.pricePerWh > maxDCTariff)
                maxDCTariff = tariff.pricePerWh
            }
          })
        })
        return {
          ...sub,
          minACTariff,
          maxACTariff,
          minDCTariff,
          maxDCTariff,
          tariffs,
          country,
          onClick: (_id, toBeRemoved) => {
            handleRemove(_id, toBeRemoved)
          },
        }
      })
      setfilteredSubscriptions(difference)
    }, [dropdownList, newDisplayedList, isEditing])

    const handleRemove = (_id, toBeRemoved) => {
      if (toBeRemoved) {
        const index = deleteSubscriptions.indexOf(_id)
        if (index === -1) {
          setDeleteSubscriptions([...deleteSubscriptions, _id])
        }
      } else {
        let index = deleteSubscriptions.indexOf(_id)
        if (index > -1) {
          setDeleteSubscriptions(deleteSubscriptions.filter((id) => id !== _id))
        }
      }
      const index = addSubscriptions.indexOf(_id)
      if (index > -1) {
        setAddSubscriptions(
          addSubscriptions.filter((id) => {
            return id !== _id
          }),
        )
      }
    }
    const handleAdd = (_id) => {
      let index = addSubscriptions.indexOf(_id)
      if (index === -1) {
        setAddSubscriptions([...addSubscriptions, _id])
      }
      index = dropdownList.findIndex((sub) => sub._id === _id)
      if (index > -1) {
        const alreadyExist = newSubscriptions.filter((sub) => {
          return sub._id === _id
        })
        if (alreadyExist.length !== 1) {
          const formattedNewSubscription = {
            ...dropdownList[index],
            origin: 'new',
          }
          setCombinedSubscriptions((prevState) => {
            return [...prevState, formattedNewSubscription]
          })
          setNewSubscriptions([...newSubscriptions, formattedNewSubscription])
        }
      }

      let newFilteredSubs = filteredSubscriptions.filter((sub) => {
        return sub._id !== _id
      })
      setfilteredSubscriptions(newFilteredSubs)
    }

    const resetValues = () => {
      setDeleteSubscriptions([])
      setAddSubscriptions([])
      setNewSubscriptions([])
      setCombinedSubscriptions((prevState) => {
        return prevState.filter((sub) => sub.origin === 'original')
      })
    }

    const handleSubmit = (resetValuesFlag) => {
      onSubmit(addSubscriptions, deleteSubscriptions)
      if (resetValuesFlag) {
        resetValues()
      }
    }

    const handleCancel = () => {
      resetValues()
    }

    const didChange = () => {
      return deleteSubscriptions.length > 0 || addSubscriptions.length > 0
    }

    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleCancel,
      didChange,
    }))

    return (
      <div
        className={
          isEditing
            ? Styles.SubscriptionListWrapperEditing
            : Styles.SubscriptionListWrapper
        }
        style={
          isEditing
            ? isLoading
              ? {}
              : {
                  height: combinedSubscriptions.length > 0 ? dynamicHeight : '',
                  maxHeight: maxListHeight,
                }
            : {}
        }
      >
        {displayedList?.length === 0 &&
        newSubscriptions.length === 0 &&
        !isLoading ? (
          <div
            className={Styles.EmptyContainer}
            style={isEditing ? { height: '4.25rem', marginTop: '0.5rem' } : {}}
          >
            <EmptySpecs title={t('ChargepointSpecs.NoSubscriptions')} />
          </div>
        ) : isLoading ? (
          <div style={{ height: scrollbarsHeight, overflow: 'hidden' }}>
            {[...Array(6)].map((e, index) => {
              return <ChargePointSubscriptionListItemSkeleton />
            })}
          </div>
        ) : (
          <div className={Styles.ListWrapper}>
            <Virtuoso
              data={combinedSubscriptions}
              increaseViewportBy={480}
              totalListHeightChanged={(height) => {
                setDynamicHeight(height + combinedSubscriptions.length * 8 + 40)
              }}
              itemContent={(index, sub) => {
                let minACTariff = Number.MAX_SAFE_INTEGER
                let maxACTariff = Number.MIN_SAFE_INTEGER
                let minDCTariff = Number.MAX_SAFE_INTEGER
                let maxDCTariff = Number.MIN_SAFE_INTEGER
                let tariffs = []
                let timeSlots = sub?.subscription?.timeSlots || sub?.timeSlots
                timeSlots?.forEach((timeslot) => {
                  timeslot.tariffs.forEach((tariff) => {
                    tariffs.push(tariff)
                    if (tariff.currentType === InfinityEnums.CurrentType.AC) {
                      if (tariff.pricePerWh < minACTariff)
                        minACTariff = tariff.pricePerWh
                      if (tariff.pricePerWh > maxACTariff)
                        maxACTariff = tariff.pricePerWh
                    } else {
                      if (tariff.pricePerWh < minDCTariff)
                        minDCTariff = tariff.pricePerWh
                      if (tariff.pricePerWh > maxDCTariff)
                        maxDCTariff = tariff.pricePerWh
                    }
                  })
                })

                if (sub.origin === 'original') {
                  return (
                    <ChargePointSubscriptionListItem
                      key={sub.subscription?._id}
                      _id={sub.subscription?._id}
                      name={sub.subscription?.name}
                      minACTariff={minACTariff}
                      minDCTariff={minDCTariff}
                      maxACTariff={maxACTariff}
                      maxDCTariff={maxDCTariff}
                      expiryDate={
                        sub.subscription?.expiresAt
                          ? moment(sub.subscription?.expiresAt).format(
                              'DD-MM-YYYY HH:mm',
                            )
                          : null
                      }
                      isDefault={sub.subscription?.isDefault}
                      power={sub.subscription?.chargePointExpiresAfter?.power}
                      consumedPower={sub.consumedPower}
                      isEditing={isEditing}
                      onClick={(_id, toBeRemoved) => {
                        handleRemove(_id, toBeRemoved)
                      }}
                      tariffs={tariffs.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => JSON.stringify(t) === JSON.stringify(value),
                          ),
                      )}
                      timeSlots={sub?.subscription.timeSlots}
                      country={country}
                    />
                  )
                } else {
                  return (
                    <ChargePointSubscriptionListItem
                      key={sub._id}
                      _id={sub._id}
                      name={sub.name}
                      power={sub?.chargePointExpiresAfter?.power}
                      isEditing={isEditing}
                      onClick={(_id, toBeRemoved) => {
                        handleRemove(_id, toBeRemoved)
                      }}
                      minACTariff={minACTariff}
                      minDCTariff={minDCTariff}
                      maxACTariff={maxACTariff}
                      maxDCTariff={maxDCTariff}
                      expiryDate={
                        sub?.expiresAt
                          ? moment(sub.subscription?.expiresAt).format(
                              'DD-MM-YYYY HH:mm',
                            )
                          : null
                      }
                      isDefault={sub?.isDefault}
                      consumedPower={sub.consumedPower}
                      tariffs={tariffs.filter(
                        (value, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => JSON.stringify(t) === JSON.stringify(value),
                          ),
                      )}
                      timeSlots={sub?.timeSlots}
                      country={country}
                    />
                  )
                }
              }}
              components={{ Scroller: CustomScrollbar }}
            />
          </div>
        )}
        {isEditing && !isLoading && (
          <div
            className={
              removeExtraMargin
                ? Styles.ChargingSpecsAddPadding
                : Styles.ChargingSpecsAdd
            }
          >
            <ChargingSpecsAdd
              data={filteredSubscriptions}
              onSelect={(value) => {
                handleAdd(value)
              }}
              RenderMenuItem={ChargePointSubscriptionListItem}
              RenderMenuItemkeys={[
                '_id',
                'name',
                'expiryDate',
                'consumedPower',
                'power',
                'isEditing',
                'minDCTariff',
                'minACTariff',
                'maxACTariff',
                'maxDCTariff',
                'isDefault',
                'tariffs',
                'timeSlots',
                'country',
                'onClick',
              ]}
            />
          </div>
        )}
      </div>
    )
  },
)

SubscriptionList.propTypes = {
  displayedList: PropTypes.array,
  dropdownList: PropTypes.array,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
  onSubmit: PropTypes.func,
  isEmpty: PropTypes.bool,
}

export default SubscriptionList
