import { IFText } from 'Components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Styles from './ConnectorOngoingActions.module.css'
import { useTranslation } from 'react-i18next'
import { Colors, Icons } from 'Theme'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import InfinityEnums from 'Enums/InfinityEnums'
import ReactTimeAgo from 'react-time-ago'
import IFsvg from 'Components/IFsvg/IFsvg'
import { isPropsMatch } from 'Utils/PropsMatch'
import { CreditCard, PhoneIphone } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'

const StyledHyperLink = styled.a`
  font-size: 14;
  vertical-align: middle;
  text-align: center;
  padding-bottom: 2px;
  margin-left: 6px;
  color: ${(props) => props.textColor};
  &:hover {
    color: ${(props) => props.hoverColor} !important;
  }
  &:before {
    content: '';
    width: 0;
    height: 0.1em;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: ${(props) => props.hoverColor} !important;
    transition: all 0.15s;
  }
  &:hover:before {
    width: 100%;
    left: 0;
    background: ${(props) => props.hoverColor} !important;
  }
`
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.tooltipBoxShadow}`,
    maxWidth: 'none',
    width: '350px',
  },
}))

const ConnectorOngoingActions = ({
  status,
  chargingTokenUid,
  powerConsumed,
  startedAt,
  reservation,
  user,
  tokenType,
}) => {
  const { t } = useTranslation()
  const [appUserIdHover, setAppUserIdHover] = useState(false)
  const [isAppUserIdCopied, setIsAppUserIdCopied] = useState(false)

  const animateUid = () => {
    setIsAppUserIdCopied(!isAppUserIdCopied)
    setTimeout(() => setIsAppUserIdCopied(false), 2000)
  }

  return (
    <div
      className={Styles.Container}
      style={{
        borderColor:
          status === InfinityEnums.ConnectorStatus.CHARGING
            ? Colors.ChargingConnector
            : Colors.PreparingConnector,
      }}
    >
      <div
        className={Styles.OngoingTextContainer}
        style={{
          backgroundColor:
            status === InfinityEnums.ConnectorStatus.CHARGING
              ? Colors.ChargingConnector
              : Colors.PreparingConnector,
        }}
      >
        <IFText className={Styles.OngoingText} style={{ color: Colors.white }}>
          {reservation
            ? t('OngoingSession.Reserved')
            : t('OngoingSession.Ongoing')}
        </IFText>
      </div>
      <div
        className={Styles.DetailsContainer}
        style={{
          backgroundColor:
            status === InfinityEnums.ConnectorStatus.CHARGING
              ? Colors.ChargingConnector + Colors.HexTransparent.T5
              : Colors.PreparingConnector + Colors.HexTransparent.T5,
        }}
      >
        <div className={Styles.TokenContainer}>
          {user ? (
            <div className={Styles.TokenContainer}>
              <div className={Styles.CreditCardIcon}>
                {tokenType === InfinityEnums.ChargingTokenType.VIRTUAL ? (
                  <PhoneIphone sx={{ fontSize: 20 }} />
                ) : (
                  <CreditCard sx={{ fontSize: 20 }} />
                )}
              </div>

              <div style={{ alignSelf: 'center' }}>
                <IFText
                  style={{ color: Colors.text }}
                  className={Styles.Middle}
                >
                  <StyledHyperLink
                    hoverColor={Colors.primary}
                    textColor={Colors.text}
                    target="_blank"
                    href={`/users/${user.id}`}
                  >
                    {user.name}
                  </StyledHyperLink>
                </IFText>
              </div>
              <HtmlTooltip
                title={
                  <div className={Styles.UserInfo}>
                    <div className={Styles.InfoLeft}>
                      <IFText
                        className={Styles.ToolTipText}
                        style={{
                          color: Colors.black,
                        }}
                      >
                        {t('UserInfo.ID')}
                      </IFText>
                      <IFText
                        className={Styles.ToolTipText}
                        style={{
                          color: Colors.black,
                        }}
                      >
                        {t('UserInfo.Name')}
                      </IFText>
                    </div>
                    <div className={Styles.InfoRight}>
                      <div className={Styles.AppUserIdContainer}>
                        <IFText
                          className={Styles.ToolTipText}
                          style={{ color: Colors.tooltipGreyBackground }}
                        >
                          {user.appUserId}
                        </IFText>
                        <div
                          className={Styles.AppUserIdCopyContainer}
                          onMouseEnter={() => setAppUserIdHover(true)}
                          onMouseLeave={() => setAppUserIdHover(false)}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {appUserIdHover ? (
                            <div
                              className={Styles.IconContainer}
                              onClick={() => {
                                navigator.clipboard.writeText(user.appUserId)
                              }}
                            >
                              <CheckIcon
                                className={
                                  isAppUserIdCopied
                                    ? Styles.visible
                                    : Styles.hide
                                }
                                style={{
                                  color: Colors.primary,
                                }}
                              />
                              <ContentCopyIcon
                                onClick={animateUid}
                                style={{ color: Colors.ClipboardCopy }}
                                className={
                                  !isAppUserIdCopied
                                    ? Styles.visible
                                    : Styles.hide
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <IFText
                        className={Styles.ToolTipText}
                        style={{
                          color: Colors.tooltipGreyBackground,
                        }}
                      >
                        {user.name}
                      </IFText>
                    </div>
                  </div>
                }
                placement="right-start"
              >
                <InfoIcon
                  style={{ color: Colors.tooltipGreyBackground }}
                  className={Styles.InfoIcon}
                ></InfoIcon>
              </HtmlTooltip>
            </div>
          ) : (
            <>
              <CreditCardIcon className={Styles.CreditCardIcon} />
              <IFText className={Styles.Text}># {chargingTokenUid}</IFText>
            </>
          )}
        </div>
        <div className={Styles.EnergyWrapper}>
          {!reservation && (
            <div className={Styles.EnergyContainer}>
              <div className={Styles.FlashContainer}>{Icons.flash}</div>
              <IFText className={Styles.Text}>
                {isNaN(powerConsumed)
                  ? `0 kWh`
                  : `${Number(powerConsumed / 1000).toFixed(3)} kWh`}
              </IFText>
            </div>
          )}
        </div>
        <div className={Styles.TimeContainer}>
          {reservation ? (
            <div style={{ alignSelf: 'center' }}>
              <IFsvg.Timelapse
                height={24}
                width={24}
                fill={Colors.black}
                style={{ alignSelf: 'center' }}
              />
            </div>
          ) : (
            <TimerOutlinedIcon className={Styles.TimerOutlinedIcon} />
          )}

          <IFText className={Styles.Text}>
            {reservation ? (
              <ReactTimeAgo
                future
                date={new Date(reservation.expiresAt)}
                locale="en-US"
                timeStyle="round"
              />
            ) : (
              <ReactTimeAgo
                date={new Date(startedAt)}
                locale="en-US"
                timeStyle="mini"
              />
            )}
          </IFText>
        </div>
      </div>
    </div>
  )
}

ConnectorOngoingActions.propTypes = {
  status: PropTypes.string,
  chargingTokenUid: PropTypes.string,
  powerConsumed: PropTypes.number,
  startedAt: PropTypes.string,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'status',
    'chargingTokenUid',
    'powerConsumed',
    'startedAt',
    'tokenType',
  ])
}
export default React.memo(ConnectorOngoingActions, shouldSkipRender)
